import React, { useEffect, useState } from "react";
import axios from "axios";
import "./fonts.css";
import "./PreviousWinner.css";
import Quote from "./Quote";
import winner_1 from "./font/winner_1.jpg";
import winner_2 from "./font/winner_2.jpg";
import winner_3 from "./font/winner_3.jpg";
import winner_4 from "./font/winner_4.jpg";
import winner_5 from "./font/winner_5.jpg";
import winner_6 from "./font/winner_6.jpg";

function PreviousWinner() {
  const getImageHeight = () => {
    const windowWidth = window.innerWidth;
    if (windowWidth <= 350) {
      return "188px";
    } else if (windowWidth <= 430) {
      return "200px";
    } else if (windowWidth <= 600) {
      return "250px";
    } else if (windowWidth <= 768) {
      return "300px";
    } else if (windowWidth <= 944) {
      return "300px";
    } else if (windowWidth <= 1024) {
      return "400px";
    } else if (windowWidth <= 1126) {
      return "400px";
    } else if (windowWidth <= 1280) {
      return "400px";
    } else if (windowWidth <= 2560) {
      return "590px";
    } else {
      return "500px";
    }
  };
  const [imageHeight, setImageHeight] = useState(getImageHeight());
  useEffect(() => {
    const handleResize = () => {
      setImageHeight(getImageHeight());
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [winners, setWinners] = useState([]);
  const images = [
    { img: winner_1, idx: 0 },
    { img: winner_2, idx: 1 },
    { img: winner_3, idx: 2 },
    { img: winner_4, idx: 3 },
    { img: winner_5, idx: 4 },
    { img: winner_6, idx: 5 },
  ];

  useEffect(() => {
    axios
      .get(`https://scoreapi.cricfan.tv/api/get_giveawaywinners`)
      .then((response) => {
        if (response.data.isSuccess) {
          const winners_data = response.data.data.map((d, index) => {
            let img_url = images.find((img) => img.idx === index);
            return { ...d, photo: img_url?.img };
          });
          setWinners(winners_data);
        }
      })
      .catch((error) => {
        console.error("Error fetching winners:", error);
      });
  }, []);

  return (
    <div>
      <div>
        <p className="winner-header-style">PREVIOUS WINNERS</p>
      </div>
      <div className="winner-container">
        {winners.map((winner) => (
          <div className="winner-item" key={winner.id}>
            <img
              src={winner.photo}
              alt="Winner"
              className="image-style img-fluid"
              style={{
                width: "80%",
                height: imageHeight,
                objectFit: "cover",
              }}
            />
            <Quote quote={winner.quote} name={winner.name} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default PreviousWinner;
