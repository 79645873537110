import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
} from "@mui/material";
import {
  convertToCurrentTimeZone,
  convertToCurrentTimeZone12Hr,
  convertUtcToTimeZone,
  convertUtcToTimeZoneSimple,
} from "./helper";
import { DateTimeComponent } from "./reusable";

function GiveawayAdmin() {
  const [giveaways, setGiveaways] = useState([]);
  const [selectedGiveawayId, setSelectedGiveawayId] = useState(null);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [addTicketsDialogOpen, setAddTicketsDialogOpen] = useState(false);
  const [updateTitle, setUpdateTitle] = useState("");
  const [updateDrawDate, setUpdateDrawDate] = useState("");
  const [updateStartDate, setUpdateStartDate] = useState("");
  const [updateEndDate, setUpdateEndDate] = useState("");
  const [updateOrder, setUpdateOrder] = useState(1);
  const [updateTotalTickets, setUpdateTotalTickets] = useState("");
  const [updateTickets, setUpdateTickets] = useState("");
  const [updateImage, setUpdateImage] = useState(null);
  const [addTitle, setAddTitle] = useState("");
  const [addDrawDate, setAddDrawDate] = useState("");
  const [addStartDate, setAddStartDate] = useState("");
  const [addEndDate, setAddEndDate] = useState("");
  const [addOrder, setAddOrder] = useState(1);
  const [addTotalTickets, setAddTotalTickets] = useState("");
  const [addTickets, setAddTickets] = useState("");
  const [addImage, setAddImage] = useState(null);
  const [addTicketsCount, setAddTicketsCount] = useState("");
  const [password, setPassword] = useState("");
  const [passwordDialogOpen, setPasswordDialogOpen] = useState(true);
  const [maintenance, setMaintenance] = useState(false);

  function convertUTCtoIST(utcDate) {
    const date = new Date(utcDate);
    //date.setHours(date.getHours() + 5);
    //date.setMinutes(date.getMinutes() + 30);
    return date;
  }

  const dateTiemConversion = (date) => {
    const formattedDate = new Date(date).toLocaleString("en-IN", {
      timeZone: "Asia/Kolkata",
      hour12: true,
    });

    return formattedDate;
  };

  const fetchGiveaways = () => {
    axios
      .get(`https://scoreapi.cricfan.tv/api/get_giveawayprizes`, {
        params: { password },
      })
      .then((response) => {
        setMaintenance(false);
        setGiveaways(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching giveaways:", error);
      });
  };

  const handleUpdateGiveaway = () => {
    // var partStart = new Date(updateStartDate);
    // //parts.setHours(parts.getHours() + 5);
    // //parts.setMinutes(parts.getMinutes() + 30);
    // var formattedStartDate =
    //   partStart.toISOString().slice(0, 16).replace("T", " ") + ":00";

    // var partend = new Date(updateEndDate);
    // //parts.setHours(parts.getHours() + 5);
    // //parts.setMinutes(parts.getMinutes() + 30);
    // var formattedEndDate =
    //   partend.toISOString().slice(0, 16).replace("T", " ") + ":00";

    // var partsDraw = new Date(updateDrawDate);
    // //partsDraw.setHours(partsDraw.getHours() + 5);
    // //partsDraw.setMinutes(partsDraw.getMinutes() + 30);
    // var formattedDrawDate = partsDraw.toISOString().slice(0, 10);

    const formData = new FormData();
    formData.append("file", updateImage);
    formData.append("prize_id", selectedGiveawayId);
    formData.append("title", updateTitle);
    formData.append("draw_date", convertToCurrentTimeZone(updateDrawDate));
    formData.append("start_date", convertToCurrentTimeZone(updateStartDate));
    formData.append("end_date", convertToCurrentTimeZone(updateEndDate));
    formData.append("total_tickets", updateTotalTickets);
    formData.append("order", updateOrder);
    formData.append("tickets", updateTickets);
    formData.append("password", password);

    axios
      .post(
        `https://scoreapi.cricfan.tv/api/post_update_giveawayprizes`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        console.log("Giveaway updated successfully");
        setUpdateDialogOpen(false);
        fetchGiveaways();
      })
      .catch((error) => {
        console.error("Error updating giveaway:", error);
      });
  };

  const handleUpdateDialogOpen = (giveaway) => {
    setSelectedGiveawayId(giveaway.id);
    setUpdateTitle(giveaway.title);
    setUpdateDrawDate(giveaway.draw_date);
    setUpdateStartDate(giveaway.start_date);
    setUpdateEndDate(giveaway.end_date);
    setUpdateTotalTickets(giveaway.total_tickets);
    setUpdateOrder(giveaway.prize_order);
    setUpdateTickets(giveaway.tickets);
    setUpdateDialogOpen(true);
  };

  const handleUpdateDialogClose = () => {
    setUpdateDialogOpen(false);
    setSelectedGiveawayId(null);
    setUpdateTitle("");
    setUpdateDrawDate("");
    setUpdateStartDate("");
    setUpdateEndDate("");
    setUpdateTotalTickets("");
    setUpdateOrder(1);
    setUpdateTickets("");
    setUpdateImage(null);
  };

  const handleRemoveGiveaway = () => {
    axios
      .post(`https://scoreapi.cricfan.tv/api/post_remove_giveawayprizes`, {
        prize_id: selectedGiveawayId,
        password,
      })
      .then((response) => {
        console.log("Giveaway removed successfully");
        setRemoveDialogOpen(false);
        fetchGiveaways();
      })
      .catch((error) => {
        console.error("Error removing giveaway:", error);
      });
  };

  const handleRemoveDialogOpen = (giveaway) => {
    setSelectedGiveawayId(giveaway.id);
    setRemoveDialogOpen(true);
  };

  const handleRemoveDialogClose = () => {
    setRemoveDialogOpen(false);
    setSelectedGiveawayId(null);
  };

  const handleAddGiveaway = () => {
    var parts = addStartDate.split("T");
    var formattedStartDate = parts[0] + " " + parts[1] + ":00";

    var parts = addEndDate.split("T");
    var formattedEndDate = parts[0] + " " + parts[1] + ":00";

    const formData = new FormData();
    formData.append("file", addImage);
    formData.append("title", addTitle);
    formData.append("draw_date", addDrawDate);
    formData.append("start_date", formattedStartDate);
    formData.append("end_date", formattedEndDate);
    formData.append("total_tickets", addTotalTickets);
    formData.append("order", addOrder);
    formData.append("tickets", addTickets);
    formData.append("password", password);

    axios
      .post(
        `https://scoreapi.cricfan.tv/api/post_add_giveawayprizes`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        console.log("Giveaway added successfully");
        setAddDialogOpen(false);
        fetchGiveaways();
      })
      .catch((error) => {
        console.error("Error adding giveaway:", error);
      });
  };

  const handlePasswordSubmit = () => {
    setPasswordDialogOpen(false);
    setMaintenance(false);
    fetchGiveaways();
  };

  const handleAddTickets = () => {
    axios
      .get(
        `https://scoreapi.cricfan.tv/api/get_addtickets?password=${password}&tickets=${addTicketsCount}`
      )
      .then((response) => {
        console.log("Tickets added successfully");
        setAddTicketsDialogOpen(false);
        fetchGiveaways();
      })
      .catch((error) => {
        console.error("Error adding tickets:", error);
      });
  };

  return (
    <div>
      <h2 style={{ color: "#AF9661" }}>Giveaway Management</h2>
      <Dialog open={passwordDialogOpen} onClose={() => {}} disableBackdropClick>
        <DialogTitle>Enter Password</DialogTitle>
        <DialogContent>
          <TextField
            label="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            fullWidth
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handlePasswordSubmit}
            variant="contained"
            style={{ backgroundColor: "#AF9661", color: "white" }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "16px",
        }}
      >
        <Button
          onClick={() => setAddDialogOpen(true)}
          variant="contained"
          style={{ backgroundColor: "#AF9661", color: "white" }}
        >
          Add Giveaway
        </Button>
        <Button
          onClick={() => setAddTicketsDialogOpen(true)}
          variant="contained"
          style={{ backgroundColor: "#AF9661", color: "white" }}
        >
          Add Tickets
        </Button>
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ backgroundColor: "#AF9661", color: "white" }}>
                ID
              </TableCell>
              <TableCell style={{ backgroundColor: "#AF9661", color: "white" }}>
                Title
              </TableCell>
              <TableCell style={{ backgroundColor: "#AF9661", color: "white" }}>
                Draw Date
              </TableCell>
              <TableCell style={{ backgroundColor: "#AF9661", color: "white" }}>
                Start Date
              </TableCell>
              <TableCell style={{ backgroundColor: "#AF9661", color: "white" }}>
                End Date
              </TableCell>
              <TableCell style={{ backgroundColor: "#AF9661", color: "white" }}>
                Order
              </TableCell>
              <TableCell style={{ backgroundColor: "#AF9661", color: "white" }}>
                Total Tickets
              </TableCell>
              <TableCell style={{ backgroundColor: "#AF9661", color: "white" }}>
                Tickets
              </TableCell>
              <TableCell style={{ backgroundColor: "#AF9661", color: "white" }}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          {maintenance ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <h1 style={{ textAlign: "center" }}>
                Website is under maintenance
              </h1>
            </div>
          ) : (
            <TableBody>
              {giveaways.map((giveaway) => {
                const formattedStartDate = convertToCurrentTimeZone12Hr(
                  giveaway?.start_date
                );
                const formattedEndDate = convertToCurrentTimeZone12Hr(
                  giveaway?.end_date
                );
                const formattedDrawDate = convertUtcToTimeZoneSimple(
                  giveaway?.draw_date
                );

                return (
                  <TableRow key={giveaway.id}>
                    <TableCell>{giveaway.id}</TableCell>
                    <TableCell>{giveaway.title}</TableCell>
                    <TableCell>{formattedDrawDate}</TableCell>
                    <TableCell>{formattedStartDate}</TableCell>
                    <TableCell>{formattedEndDate}</TableCell>
                    <TableCell>{giveaway.prize_order}</TableCell>
                    <TableCell>{giveaway.total_tickets}</TableCell>
                    <TableCell>{giveaway.tickets}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        onClick={() => handleUpdateDialogOpen(giveaway)}
                        style={{
                          backgroundColor: "#AF9661",
                          color: "white",
                          marginRight: "8px",
                        }}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => handleRemoveDialogOpen(giveaway)}
                        style={{ backgroundColor: "#AF9661", color: "white" }}
                      >
                        Remove
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <Dialog open={updateDialogOpen} onClose={handleUpdateDialogClose}>
        <DialogTitle>Edit Giveaway</DialogTitle>
        <DialogContent>
          <TextField
            label="Title"
            value={updateTitle}
            onChange={(e) => setUpdateTitle(e.target.value)}
            fullWidth
            variant="outlined"
            style={{ marginBottom: "16px" }}
          />
          {/* <DateTimeComponent
            title="Draw Date"
            value={
              updateDrawDate ? convertToCurrentTimeZone(updateDrawDate) : ""
            }
            onChange={setUpdateDrawDate}
            isDate={true}
          />
          <DateTimeComponent
            title="Start Date and Time"
            value={
              updateStartDate ? convertToCurrentTimeZone(updateStartDate) : ""
            }
            onChange={setUpdateStartDate}
          />
          <DateTimeComponent
            title="End Date and Time"
            value={updateEndDate ? convertToCurrentTimeZone(updateEndDate) : ""}
            onChange={setUpdateEndDate}
          /> */}
          <TextField
            label="Draw Date"
            type="date"
            value={
              updateDrawDate ? convertUtcToTimeZoneSimple(updateDrawDate) : ""
            }
            onChange={(e) => setUpdateDrawDate(e.target.value)}
            fullWidth
            variant="outlined"
            style={{ marginBottom: "16px" }}
          />
          <TextField
            label="Start Date and Time"
            type="datetime-local"
            value={updateStartDate ? convertUtcToTimeZone(updateStartDate) : ""}
            onChange={(e) => setUpdateStartDate(new Date(e.target.value))}
            fullWidth
            variant="outlined"
            style={{ marginBottom: "16px" }}
          />
          <TextField
            label="End Date and Time"
            type="datetime-local"
            value={updateEndDate ? convertUtcToTimeZone(updateEndDate) : ""}
            onChange={(e) => setUpdateEndDate(new Date(e.target.value))}
            fullWidth
            variant="outlined"
            style={{ marginBottom: "16px" }}
          />
          <TextField
            label="Order"
            type="number"
            value={updateOrder}
            onChange={(e) => setUpdateOrder(e.target.value)}
            fullWidth
            variant="outlined"
            style={{ marginBottom: "16px" }}
          />
          <TextField
            label="Total Tickets"
            type="number"
            value={updateTotalTickets}
            onChange={(e) => setUpdateTotalTickets(e.target.value)}
            fullWidth
            variant="outlined"
            style={{ marginBottom: "16px" }}
          />
          <TextField
            label="Tickets"
            type="number"
            value={updateTickets}
            onChange={(e) => setUpdateTickets(e.target.value)}
            fullWidth
            variant="outlined"
            style={{ marginBottom: "16px" }}
          />
          <input
            type="file"
            onChange={(e) => setUpdateImage(e.target.files[0])}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleUpdateGiveaway}
            variant="contained"
            style={{ backgroundColor: "#AF9661", color: "white" }}
          >
            Update
          </Button>
          <Button
            onClick={handleUpdateDialogClose}
            variant="contained"
            style={{ backgroundColor: "#AF9661", color: "white" }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={removeDialogOpen} onClose={handleRemoveDialogClose}>
        <DialogTitle>Remove Giveaway</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to remove this giveaway?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleRemoveGiveaway}
            variant="contained"
            style={{ backgroundColor: "#AF9661", color: "white" }}
          >
            Yes
          </Button>
          <Button
            onClick={handleRemoveDialogClose}
            variant="contained"
            style={{ backgroundColor: "#AF9661", color: "white" }}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={addDialogOpen} onClose={() => setAddDialogOpen(false)}>
        <DialogTitle>Add Giveaway</DialogTitle>
        <DialogContent>
          <TextField
            label="Title"
            value={addTitle}
            onChange={(e) => setAddTitle(e.target.value)}
            fullWidth
            variant="outlined"
            style={{ marginBottom: "16px" }}
          />
          <TextField
            label="Draw Date"
            type="date"
            value={addDrawDate}
            onChange={(e) => setAddDrawDate(e.target.value)}
            fullWidth
            variant="outlined"
            style={{ marginBottom: "16px" }}
          />
          <TextField
            label="Start Date and Time"
            type="datetime-local"
            value={addStartDate}
            onChange={(e) => setAddStartDate(e.target.value)}
            fullWidth
            variant="outlined"
            style={{ marginBottom: "16px" }}
          />
          <TextField
            label="End Date and Time"
            type="datetime-local"
            value={addEndDate}
            onChange={(e) => setAddEndDate(e.target.value)}
            fullWidth
            variant="outlined"
            style={{ marginBottom: "16px" }}
          />
          <TextField
            label="Order"
            type="number"
            value={addOrder}
            onChange={(e) => setAddOrder(e.target.value)}
            fullWidth
            variant="outlined"
            style={{ marginBottom: "16px" }}
          />
          <TextField
            label="Total Tickets"
            type="number"
            value={addTotalTickets}
            onChange={(e) => setAddTotalTickets(e.target.value)}
            fullWidth
            variant="outlined"
            style={{ marginBottom: "16px" }}
          />
          <TextField
            label="Tickets"
            type="number"
            value={addTickets}
            onChange={(e) => setAddTickets(e.target.value)}
            fullWidth
            variant="outlined"
            style={{ marginBottom: "16px" }}
          />
          <input type="file" onChange={(e) => setAddImage(e.target.files[0])} />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleAddGiveaway}
            variant="contained"
            style={{ backgroundColor: "#AF9661", color: "white" }}
          >
            Add
          </Button>
          <Button
            onClick={() => setAddDialogOpen(false)}
            variant="contained"
            style={{ backgroundColor: "#AF9661", color: "white" }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={addTicketsDialogOpen}
        onClose={() => setAddTicketsDialogOpen(false)}
      >
        <DialogTitle>Add Tickets</DialogTitle>
        <DialogContent>
          <TextField
            label="Tickets Count"
            type="number"
            value={addTicketsCount}
            onChange={(e) => setAddTicketsCount(e.target.value)}
            fullWidth
            variant="outlined"
            style={{ marginBottom: "16px" }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleAddTickets}
            variant="contained"
            style={{ backgroundColor: "#AF9661", color: "white" }}
          >
            Add
          </Button>
          <Button
            onClick={() => setAddTicketsDialogOpen(false)}
            variant="contained"
            style={{ backgroundColor: "#AF9661", color: "white" }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default GiveawayAdmin;
