import React, { useState, useEffect } from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import { showSuccessPopup, showErrorPopup } from "./Notification";

function Login({ prizeId, prizeText, hideOverlay, hideAndShowThankYou }) {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [otpGenerated, setOtpGenerated] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [resendTimer, setResendTimer] = useState(30);
  const [disableResend, setDisableResend] = useState(false);

  useEffect(() => {
    let interval;
    if (resendTimer > 0 && disableResend) {
      interval = setInterval(() => {
        setResendTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      clearInterval(interval);
      setDisableResend(false);
      setOtpGenerated(false);
    }
    return () => clearInterval(interval);
  }, [resendTimer, disableResend]);

  const handleGenerateOTP = () => {
    setOtpGenerated(true);
    const apiEndpoint =
      `https://scoreapi.cricfan.tv/api/get_login_sendotp_v2?phone_no=` +
      phoneNumber;
    fetch(apiEndpoint)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.status === 200) {
          setOtpSent(true);
          setDisableResend(true);
          setResendTimer(30);
        } else {
          showErrorPopup(data.message);
        }
      })
      .catch((error) => {
        showErrorPopup(error.message);
      });
  };

  const handleLogin = () => {
    const apiEndpoint = `https://scoreapi.cricfan.tv/api/get_motus?phone_no=${phoneNumber}&prize_id=${prizeId}&prize_name=${prizeText}&otp=${otp}`;

    fetch(apiEndpoint)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.status === 200) {
          localStorage.setItem("jwtToken", data.token);
          if (data.error) {
            showErrorPopup(data.error);
            hideOverlay();
          } else {
            showSuccessPopup("Entry recorded!");
            hideAndShowThankYou();
          }
        } else {
          showErrorPopup(data.message);
        }
      })
      .catch((error) => {
        showErrorPopup(error.message);
      });
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    if (value.length <= 10) {
      setPhoneNumber(value);
    }
  };

  return (
    <div>
      <Form>
        <Row className="mb-3 align-items-center" style={{ marginTop: "10px" }}>
          <Col style={{ display: "flex", alignItems: "center" }}>
            <Form.Control
              type="number"
              placeholder="Enter your phone number"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              style={{ fontSize: "12px", height: "55px" }}
            />
          </Col>
          <Col xs="auto">
            <Button
              onClick={handleGenerateOTP}
              style={{
                borderRadius: "5px",
                width: "100%",
                backgroundColor: "#01FF76",
                color: "black",
                fontFamily: "gothic",
                fontSize: "20px",
                border: "0px",
              }}
              disabled={disableResend}
            >
              Generate OTP
            </Button>
          </Col>
        </Row>

        {otpGenerated ? (
          <div>
            <p style={{ color: "white" }}>
              {!disableResend && otpGenerated && (
                <p>You will receive a message with OTP</p>
              )}
              {disableResend && ` Re-send OTP in ${resendTimer} seconds`}
            </p>
          </div>
        ) : null}

        {otpSent ? (
          <div>
            <Form.Group controlId="otp">
              <Form.Control
                type="text"
                placeholder="Enter OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                maxLength={4}
              />
            </Form.Group>
          </div>
        ) : null}

        <Row
          className="mb-3 align-items-center"
          style={{ marginTop: "10px", marginRight: "10px" }}
        >
          <Button
            onClick={handleLogin}
            style={{
              marginLeft: "10px",
              borderRadius: "5px",
              backgroundColor: "#01FF76",
              color: "black",
              fontFamily: "gothic",
              fontSize: "20px",
              border: "0px",
            }}
          >
            Login
          </Button>
        </Row>
      </Form>
    </div>
  );
}

export default Login;
