import React from 'react';
import './CircleSlider.css'; // Stylesheet

const CircleSlider = ({ images }) => {
    const renderImages = () => {
        const totalImages = images.length;
        const overlap = 85;
        const imageSize = 100;
        const totalWidth = totalImages * (imageSize - overlap) + overlap;

        return images.map((image, index) => {
            const leftPosition = (index * (imageSize - overlap) + overlap / 2) * 100 / totalWidth;

            const imageStyle = {
                left: `${leftPosition}%`,
                transform: `translateX(-50%)`
            };

            return (
                <img
                    key={index}
                    src={image}
                    alt={`Image ${index}`}
                    className="circle-image"
                    style={imageStyle}
                />
            );
        });
    };

    return (
        <div className="circle-slider">
            {renderImages()}
        </div>
    );
};

export default CircleSlider;