import React from 'react';
import './Quote.css';

function Quote({ quote, name, location }) {
    return (
        <div className="quote-container">
            <div className="quote">
                <span className="quote-mark quote-mark-left">“</span>
                <span className="quote-text">{quote}</span>
                <span className="quote-mark-right">”</span>
            </div>
            <div className="author-info">
                {/* <p>{name}, {location}</p> */}
                <p>{name}</p>
            </div>
            <br></br>
            <br></br>
        </div>
    );
}

export default Quote;