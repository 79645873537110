// TelegramIcon.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTelegramPlane } from '@fortawesome/free-brands-svg-icons';
import './TelegramIcon.css'; // Create this CSS file for styling

const TelegramIcon = () => {
    const openTelegramChannel = () => {
        window.open('https://t.me/+BMPkczZB2xk5ZDZk', '_blank');
    };

    return (
        <div className="telegram-icon" onClick={openTelegramChannel}>
            <FontAwesomeIcon icon={faTelegramPlane} size="2x" />
        </div>
    );
};

export default TelegramIcon;