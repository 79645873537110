import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Confetti from 'react-confetti';
import './TelegramDialog.css';

const TelegramDialog = ({ show, onHide }) => {
    return (
        <>
            {show && <Confetti width={window.innerWidth} height={window.innerHeight} />}
            <Modal show={show} onHide={onHide} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Competition Entered!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ textAlign: 'center' }}>
                        <p>🎉 Join our Telegram Channel to Stay Updated and Win More Exciting Prizes! 🎁✨</p>
                        <p>Don't miss out on the fun. Click Below</p>
                        <a href="https://t.me/+BMPkczZB2xk5ZDZk" target="_blank" rel="noopener noreferrer">
                            <img src={`${process.env.PUBLIC_URL}/telegram.png`} alt="Join Telegram" style={{ width: '50px', height: '50px' }} />
                        </a>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onHide}>
                        Dismiss
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default TelegramDialog;