import React, { useState } from 'react';
import CircleSlider from './CircleSlider';
import './Hero.css';
import Signup from './Signup';

const subHeaderStyle = {
    fontFamily: 'gothic',
    fontSize: '35px',
    color: 'white',
    marginTop: '10px',
    textAlign: 'left',
};

const buttonStyle = {
    fontFamily: 'gothic',
    backgroundColor: '#01FF76',
    color: 'black',
    marginTop: '30px',
    fontWeight: 'bold',
    display: 'flex',
    fontSize: '30px',
    marginLeft: '7%',
};

const divStyle = {
    fontFamily: 'gothic',
    backgroundColor: '#01FF76',
    color: 'black',
    marginTop: '10px',
    fontSize: '35px',
    padding: '10px',
};

function HeroSection(props) {

    const [showOverlay, setShowOverlay] = useState(false);

    const handleFreeToPlayClick = () => {
        props.setMoveToPlaySection(true);
    };

    const handleSimpleSignUpClick = () => {
        setShowOverlay(true);
    };

    const hideOverlay = () => {
        setShowOverlay(false);
    };

    const handleLiveDrawsClick = () => {
        window.open('https://www.instagram.com/cricfantv/', '_blank');
    };

    const images = [
        '/winner/new_winner_1.jpeg',
        '/winner/new_winner_2.jpeg',
        '/winner/new_winner_3.jpeg',
    ];

    return (
        <div className="parent-container">
            <div className="container">
                <div className="left-column">
                    <h1 className="cricfan-header">CRICWIN COMPETITIONS</h1>
                    <div className="sub-container">
                        <img className="cricfan-logo" src="/cflogo-no-name.png" style={{ maxWidth: '60%', height: 'auto' }} />
                        <div className="info-container">

                        </div>
                    </div>
                    <div className="bottom-sub-container">
                        <button className="play-now-button" style={{ ...buttonStyle }} onClick={handleFreeToPlayClick}>PLAY NOW</button>
                        <CircleSlider className="trustpilot-image" images={images} style={{ marginLeft: 'auto', marginRight: 'auto', width: '150px', height: '50px' }} />
                        <img className='trustpilot-rating' src="/trustpilot-rating.png" style={{ width: '150px', marginTop: '35px', marginLeft: '-80px', height: '60px' }} />
                    </div>
                </div>
                <div className="right-column">
                    <img className="hero-image-style" src="/banner.png" alt="Image" />
                </div>
            </div>
            <div className="additional-div" style={{ ...divStyle }}>
                <div style={{ flex: 1, cursor: 'pointer' }} onClick={handleFreeToPlayClick}>Free To Play</div>
                <div style={{ flex: 1, cursor: 'pointer' }} onClick={handleSimpleSignUpClick}>Simple Sign Up</div>
                <div style={{ flex: 1, cursor: 'pointer' }} onClick={handleLiveDrawsClick}>Live Draws on Instagram</div>
            </div>
            {
                showOverlay && (
                    <div className="overlay">
                        <div className="overlay-content">
                            <div className="close-icon" onClick={() => setShowOverlay(false)}>
                                <i className="fas fa-times"></i>
                            </div>
                            <Signup prizeId={-1} prizeText={-1} hideOverlay={hideOverlay} hideAndShowThankYou={hideOverlay} />
                        </div>
                    </div>
                )
            }
        </div >
    );
}

export default HeroSection;