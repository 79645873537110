import moment from "moment";
import "moment-timezone";

export const convertUtcToTimeZone = (dateTime) => {
  const istDateTime = moment
    .utc(dateTime)
    .tz("Asia/Kolkata")
    .format("YYYY-MM-DD HH:mm:ss");

  return istDateTime;
};

export const convertUtcToTimeZoneSimple = (dateTime) => {
  const istDateTime = moment
    .utc(dateTime)
    .tz("Asia/Kolkata")
    .format("YYYY-MM-DD");

  return istDateTime;
};

export const convertToCurrentTimeZone = (dateTime) => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const formattedTimezone = moment(dateTime)
    .tz("Asia/Kolkata")
    .format("YYYY-MM-DD HH:mm:ss");

  return formattedTimezone;
};

export const convertToCurrentTimeZone12Hr = (dateTime) => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const formattedTimezone = moment
    .utc(dateTime)
    .tz("Asia/Kolkata")
    .format("YYYY-MM-DD hh:mm A");

  return formattedTimezone;
};

export const timeComparison = (dateTime) => {
  const dbStartTimeUTC = moment(dateTime).utc();
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const dbStartTimeLocal = dbStartTimeUTC.tz(timeZone);
  const currentTimeLocal = moment().tz(timeZone);
  const isPastStartTime = dbStartTimeLocal.isBefore(currentTimeLocal);

  return isPastStartTime;
};

export const timeDifference = (dateTime) => {
  const dbStartTimeUTC = moment(dateTime).utc();
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const dbStartTimeLocal = dbStartTimeUTC.tz(timeZone);
  const currentTimeLocal = moment().tz(timeZone);
  const startDifference = dbStartTimeLocal.diff(currentTimeLocal);

  return startDifference;
};
