import React from "react";
import { FaUser } from 'react-icons/fa'; // Import the profile icon from react-icons
import './fonts.css';

function Nav() {
  const token = localStorage.getItem('jwtToken');

  // Combined styles object for menu items and icon
  const menuItemStyle = {
    fontFamily: 'gothic',
    fontSize: '40px',
  };

  const iconStyle = {
    width: '100px',
    height: '100px',
  };

  const profileIconStyle = {
    color: 'white'
  };

  return (
    <nav className="menu">
      <input type="checkbox" id="menu-toggle" />
      <label htmlFor="menu-toggle" className="menu-icon"></label>
      <a href="https://www.cricfan.tv/">
        <p><img src="/cflogo.png" style={iconStyle} alt="Cricfan Logo" /></p>
      </a>
      <ul>
        <li>
          <a className="dropdown" href="https://www.cricfan.tv/" style={menuItemStyle}>
            Home
          </a>
        </li>
        <li>
          <a className="dropdown" href="https://www.cricfan.tv/#ourshows" style={menuItemStyle}>
            Our Shows
          </a>
        </li>
        <li>
          <a className="dropdown" href="https://www.cricfan.tv/#news" style={menuItemStyle}>
            News
          </a>
        </li>
        <li>
          <a className="dropdown" href="https://www.cricfan.tv/#contact-us" style={menuItemStyle}>
            Contact Us
          </a>
        </li>
        {/* {token && (
          <li>
            <a href="/profile" style={profileIconStyle}>
              <FaUser />
            </a>
          </li>
        )} */}
      </ul>
    </nav>
  );
}

export default Nav;