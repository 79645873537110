import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Nav from "./Nav";
import Card from "./Card";
import "bootstrap/dist/css/bootstrap.min.css";
import PreviousWinner from "./PreviousWinner";
import HeroSection from "./HeroSection";
import LoadingIcon from "./LoadingIcon";
import TelegramIcon from "./TelegramIcon";
import TelegramDialog from "./TelegramDialog";
import Confetti from "react-confetti";
import "./App.css";
import prizes_1 from "./font/prizes_1.jpg";
import prizes_2 from "./font/prizes_2.jpg";
import prizes_3 from "./font/prizes_3.jpg";
import prizes_4 from "./font/prizes_4.jpg";
import prizes_5 from "./font/prizes_5.jpg";
import prizes_6 from "./font/prizes_6.jpg";
import { convertUtcToTimeZone } from "./helper";

function GiveawaySample() {
  const [showThankYou, setShowThankYou] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [moveToPlaySection, setMoveToPlaySection] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const hideThankYou = () => {
    setShowThankYou(false);
    setShowConfetti(false);
  };

  const fetchData = async () => {
    try {
      const response = await fetch(
        `https://scoreapi.cricfan.tv/api/get_giveawayprizes`
      );
      const jsonData = await response.json();

      const fetchedData = jsonData.data.map((item, index) => {
        const utcDate = new Date(item.draw_date);
        let istDate = "";
        if (isNaN(utcDate.getTime())) {
          console.error("Invalid date:", item.draw_date);
        } else {
          const convertedDate = convertUtcToTimeZone(item?.draw_date);

          istDate = convertedDate.split(" ")[0];
        }

        let image_url = item?.photo;

        const finalData = {
          id: item.id.toString(),
          title: item.title,
          image: image_url,
          guaranteedDraw: `GUARANTEED DRAW @ ${istDate}`,
          startTime: item.start_date,
          endTime: item.end_date,
          maxTicket: item.total_tickets.toString(),
          row_count: item.count,
        };

        return finalData;
      });
      setData(fetchedData);

      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (showThankYou) {
      setShowConfetti(true);
      const timer = setTimeout(() => {
        setShowConfetti(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [showThankYou]);

  const calculateFontSize = (baseFontSize, minFontSize) => {
    const screenWidth = window.innerWidth;
    const maxLength = screenWidth <= 768 ? 5 : 14;
    const scaleFactor = Math.max(1, 8 - maxLength);
    const newSize = baseFontSize - scaleFactor * 2;
    return `${Math.max(newSize, minFontSize)}px`;
  };

  const headerStyle = {
    fontFamily: "gothic",
    fontSize: "80px",
    color: "#01FF76",
    marginTop: "50px",
  };

  const headerFontSize = calculateFontSize(80, 80);

  const headerStyleDynamic = {
    ...headerStyle,
    fontSize: headerFontSize,
  };

  useEffect(() => {
    if (moveToPlaySection) {
      const playSection = document.getElementById("card-parent-box");
      if (playSection) {
        playSection.scrollIntoView({ behavior: "smooth" });
      }
      setMoveToPlaySection(false);
    }
  }, [moveToPlaySection]);

  const handleShowThankYou = (bool) => {
    if (bool) {
      const audio = new Audio(`${process.env.PUBLIC_URL}/sound.mp3`);
      audio.play();
    }
    setShowThankYou(bool);
  };

  return (
    <div
      className="App"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/cricfan_bg.png)`,
      }}
    >
      <Nav />
      <Header />
      <section className="pricing">
        {loading ? (
          <LoadingIcon />
        ) : (
          <>
            <div className="card-parent-box" id="card-parent-box">
              <div className="row">
                {data.map((e, i) => (
                  <Card
                    cardData={e}
                    key={i}
                    handleShowThankYou={handleShowThankYou}
                  />
                ))}
              </div>
            </div>
          </>
        )}
      </section>
      <HeroSection setMoveToPlaySection={setMoveToPlaySection} />
      <PreviousWinner />
      <Footer />
      <TelegramIcon />
      {showThankYou && (
        <TelegramDialog show={showThankYou} onHide={() => hideThankYou()} />
      )}
      {showConfetti && (
        <div className="confetti-wrapper">
          <Confetti width={window.innerWidth} height={window.innerHeight} />
        </div>
      )}
    </div>
  );
}

export default GiveawaySample;
