import ProgressBar from 'react-bootstrap/ProgressBar';
import React, { useEffect, useRef } from 'react';

function CustomProgressBar({ value, labelText }) {

    const ref = useRef(null);

    useEffect(() => {
        if (ref.current) {
            const inner = ref.current.querySelector(".progress-bar");
            if (inner) {
                inner.style.backgroundColor = "#01FF76";
                inner.style.color = "#000000";
            }
        }
    }, [ref]);

    return (
        <div style={{ position: 'relative', height: '2rem' }}>
            <ProgressBar
                style={{
                    height: '2rem',
                    fontSize: '18px',
                }}
                ref={ref}
                now={value}
                label={value === 0 ? "" : `${labelText}`}
            />
            {value === 0 && (
                <div style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: '#000000',
                    fontSize: '18px',
                    fontWeight: 'bold'
                }}>
                    SOLD OUT
                </div>
            )}
        </div>
    );
}

export default CustomProgressBar;