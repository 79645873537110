import React from "react";
import './Footer.css';

function Footer() {
  return (
    <div className="footer-main">
      <footer>
        <div className="footer-left">
          <img className="footer-cf-logo" src="/cflogo.png" style={{ marginLeft: "20px", width: '20%', height: 'auto' }} />
          <p className="footer-web-only">&copy; 2024 – CRICFAN.TV</p>
        </div>
        <div className="footer-right">
          <p>
            At CricFan, we’re dedicated to providing a community for cricket fans to engage with each other,
            share their love for the sport, and stay up-to-date on the latest news and events.
          </p>
          <p className="footer-mobile-only">&copy; 2024 – CRICFAN.TV</p>
          <div className="social-icons">
            <a href="https://www.linkedin.com/company/cricfan/" target="_blank"><img src="/linkedin-icon.png" alt="LinkedIn" style={{ width: "35px", height: "35px" }} /></a>
            <a href="https://www.facebook.com/CricFanTV" target="_blank"><img src="/facebook-icon.png" alt="Facebook" style={{ width: "35px", height: "35px" }} /></a>
            <a href="https://www.youtube.com/@CricFanTV" target="_blank"><img src="/youtube-icon.png" alt="YouTube" style={{ width: "35px", height: "35px" }} /></a>
            <a href="https://www.instagram.com/cricfantv/" target="_blank"><img src="/instagram-icon.png" alt="Instagram" style={{ width: "35px", height: "35px" }} /></a>
            <a href="https://twitter.com/cricfantv" target="_blank"><img src="/x-icon.png" alt="X" style={{ width: "35px", height: "35px" }} /></a>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;