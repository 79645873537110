import React from 'react';
import './fonts.css';
import './Header.css';

const headerStyle = {
  fontFamily: 'gothic',
  fontSize: '150px',
  color: '#01FF76',
  marginTop: '100px',
};

const calculateFontSize = (baseFontSize, minFontSize) => {
  const screenWidth = window.innerWidth; // Get the width of the viewport

  // Adjust maxLength based on the screen width for mobile
  const maxLength = screenWidth <= 768 ? 5 : 14; // Adjust maxLength for smaller screens

  // Calculate the new font size based on text length
  const scaleFactor = Math.max(1, 8 - maxLength); // Ensure scaleFactor is at least 1
  const newSize = baseFontSize - scaleFactor * 2; // Reduce 2px for each extra character

  // Ensure font size does not go below the minimum font size
  return `${Math.max(newSize, minFontSize)}px`;
};

const headerFontSize = calculateFontSize(80, 60);

const headerStyleDynamic = {
  ...headerStyle,
  fontSize: headerFontSize,
};

function Header() {
  return (
    <div>
      <div className='head1'>
        <p className="competitions-header" style={{ ...headerStyleDynamic }}>COMPETITIONS</p>
      </div>
    </div>
  );
}

export default Header;