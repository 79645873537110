import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom'; // Import BrowserRouter, Routes, Route, and useLocation
import GiveawaySample from './GiveawaySample'; // Import GiveawaySample component
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GiveawayAdmin from './GiveawayAdmin';
import GiveawayAdminData from './GiveawayAdminData';
import GiveawayAdminUserData from './GiveawayAdminUserData';
import UserProfile from './UserProfile';
import ReactGA from 'react-ga';
import GiveawayAdminWinners from './GiveawayAdminWinners';
import GiveawayAdminWinnersDraw from './GiveawayAdminWinnersDraw';

ReactGA.initialize('G-EPHZK822EL');

const TrackPageView = () => {
  const location = useLocation();

  useEffect(() => {
    console.log(process.env.NODE_ENV, "sasasasa")
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return null;
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <TrackPageView />
      <Routes>
        {/* Define routes using Route component */}
        <Route path="/" element={<GiveawaySample />} />
        <Route path="/giveaway" element={<GiveawaySample />} />
        <Route path="/admin" element={<GiveawayAdmin />} />
        <Route path="/adminWinners" element={<GiveawayAdminWinners />} />
        <Route path="/adminWinnersDraw" element={<GiveawayAdminWinnersDraw />} />
        <Route path="/adminData" element={<GiveawayAdminData />} />
        <Route path="/adminUserData" element={<GiveawayAdminUserData />} />
        <Route path="/profile" element={<UserProfile />} />
      </Routes>
    </Router>
    <ToastContainer
      position="top-center"
      autoClose={2000} />
  </React.StrictMode>,
);