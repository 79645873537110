import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { saveAs } from "file-saver";

function GiveawayAdminData() {
  const [password, setPassword] = useState("");
  const [dialogOpen, setDialogOpen] = useState(true);
  const [giveawayData, setGiveawayData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filters, setFilters] = useState({
    prizeName: "",
    phoneNumber: "",
    emailId: "",
    name: "",
    gender: "",
    team: "",
  });
  const [dateRange, setDateRange] = useState({
    from: "",
    to: "",
  });
  const [uniqueUserOnly, setUniqueUserOnly] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(50);
  const [serialNumberStart, setSerialNumberStart] = useState(1);

  const calculateSerialNumberStart = () => {
    return (page - 1) * limit + 1;
  };

  useEffect(() => {
    fetchData();
    setSerialNumberStart(calculateSerialNumberStart());
  }, [page, limit]); // Fetch data when page or limit changes

  const fetchData = () => {
    if (dateRange.from && dateRange.to && dateRange.to < dateRange.from) {
      window.alert('"To" date cannot be less than "From" date.');
      console.error('"To" date cannot be less than "From" date.');
      return;
    }

    let queryParams = `?password=${password}`;

    for (let key in filters) {
      if (filters[key]) {
        queryParams += `&${key}=${filters[key]}`;
      }
    }

    if (dateRange.from) {
      queryParams += `&from=${dateRange.from}`;
    }

    if (dateRange.to) {
      queryParams += `&to=${dateRange.to}`;
    }

    queryParams += `&uniqueUserOnly=${uniqueUserOnly ? 1 : 0}`;

    axios
      .get(
        `https://scoreapi.cricfan.tv/api/get_giveaway_data_v2${queryParams}&page=${page}&limit=${limit}`
      )
      .then((response) => {
        const data = response.data.data;
        setGiveawayData(data);
        setFilteredData(data);
        const totalCount = response.data.totalCount;
        setTotalPages(Math.ceil(totalCount / limit));
      })
      .catch((error) => {
        console.error("Error fetching giveaway data:", error);
      });
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    fetchData();
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const applyFilters = () => {
    if (!password) {
      console.error("Password is required to apply filters.");
      return;
    }

    if (dateRange.from && dateRange.to && dateRange.to < dateRange.from) {
      window.alert('"To" date cannot be less than "From" date.');
      console.error('"To" date cannot be less than "From" date.');
      return;
    }

    let queryParams = `?password=${password}`;

    for (let key in filters) {
      if (filters[key]) {
        queryParams += `&${key}=${filters[key]}`;
      }
    }

    if (dateRange.from) {
      queryParams += `&from=${dateRange.from}`;
    }

    if (dateRange.to) {
      queryParams += `&to=${dateRange.to}`;
    }

    queryParams += `&uniqueUserOnly=${uniqueUserOnly ? 1 : 0}`;

    axios
      .get(
        `https://scoreapi.cricfan.tv/api/get_giveaway_data_v2${queryParams}&page=${page}&limit=${limit}`
      )
      .then((response) => {
        const data = response.data.data;
        setGiveawayData(data);
        setFilteredData(data);
        const totalCount = response.data.totalCount;
        setTotalPages(Math.ceil(totalCount / limit));
      })
      .catch((error) => {
        console.error("Error fetching giveaway data:", error);
      });
  };

  const clearFilters = () => {
    if (!password) {
      console.error("Password is required to clear filters.");
      return;
    }

    setFilters({
      prizeName: "",
      phoneNumber: "",
      emailId: "",
      name: "",
      gender: "",
      team: "",
    });

    axios
      .get(
        `https://scoreapi.cricfan.tv/api/get_giveaway_data_v2?password=${password}&page=${page}&limit=${limit}`
      )
      .then((response) => {
        const data = response.data.data;
        setGiveawayData(data);
        setFilteredData(data);
        const totalCount = response.data.totalCount;
        setTotalPages(Math.ceil(totalCount / limit));
      })
      .catch((error) => {
        console.error("Error fetching giveaway data:", error);
      });
  };

  const handlePageChange = (event, newPage) => {
    setPage(page + 1);
  };

  const handlePreviousPageChange = (event, newPage) => {
    setPage(page - 1);
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(1); // Reset to first page when limit changes
  };

  const exportToCSV = () => {
    const csvData = [
      [
        "Serial Number",
        "ID",
        "Phone Number",
        "Email ID",
        "Name",
        "Age",
        "Gender",
        "Place",
        "Team",
        "Prize ID",
        "Prize Name",
        "Created On",
      ],
    ];
    filteredData.forEach((row, index) => {
      csvData.push([
        serialNumberStart + index,
        row.id,
        row.phoneNumber,
        row.emailid,
        row.name,
        row.age,
        row.gender,
        row.place,
        row.team,
        row.prize_id,
        row.prize_name,
        new Date(row.created_on).toLocaleString(),
      ]);
    });

    const csvContent = csvData.map((row) => row.join(",")).join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
    saveAs(blob, "giveaway_data.csv");
  };

  return (
    <div>
      <Dialog open={dialogOpen} onClose={() => {}} disableBackdropClick>
        <DialogTitle>Enter Password</DialogTitle>
        <DialogContent>
          <TextField
            label="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            fullWidth
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDialogClose}
            variant="contained"
            style={{ backgroundColor: "#AF9661", color: "white" }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <div style={{ marginBottom: "20px" }}>
        <TextField
          label="Prize Name"
          name="prizeName"
          value={filters.prizeName}
          onChange={handleFilterChange}
          style={{ marginRight: "20px" }}
        />
        <TextField
          label="Phone Number"
          name="phoneNumber"
          value={filters.phoneNumber}
          onChange={handleFilterChange}
          style={{ marginRight: "20px" }}
        />
        <TextField
          label="Email ID"
          name="emailId"
          value={filters.emailId}
          onChange={handleFilterChange}
          style={{ marginRight: "20px" }}
        />
        <TextField
          label="Name"
          name="name"
          value={filters.name}
          onChange={handleFilterChange}
          style={{ marginRight: "20px" }}
        />
        <TextField
          label="Gender"
          name="gender"
          value={filters.gender}
          onChange={handleFilterChange}
          style={{ marginRight: "20px" }}
        />
        <TextField
          label="Team"
          name="team"
          value={filters.team}
          onChange={handleFilterChange}
          style={{ marginRight: "20px" }}
        />
        <TextField
          label="From"
          name="from"
          type="date"
          value={dateRange.from}
          onChange={(e) => setDateRange({ ...dateRange, from: e.target.value })}
          InputLabelProps={{
            shrink: true,
          }}
          style={{ marginRight: "20px" }}
        />
        <TextField
          label="To"
          name="to"
          type="date"
          value={dateRange.to}
          onChange={(e) => setDateRange({ ...dateRange, to: e.target.value })}
          InputLabelProps={{
            shrink: true,
          }}
          style={{ marginRight: "20px" }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={uniqueUserOnly}
              onChange={(e) => setUniqueUserOnly(e.target.checked)}
              color="primary"
            />
          }
          label="Unique User Only"
          style={{ marginRight: "20px" }}
        />
        <Button
          onClick={applyFilters}
          variant="contained"
          style={{
            backgroundColor: "#AF9661",
            color: "white",
            marginRight: "20px",
          }}
        >
          Apply Filters
        </Button>
        <Button
          onClick={clearFilters}
          variant="contained"
          style={{ backgroundColor: "#AF9661", color: "white" }}
        >
          Clear Filters
        </Button>
      </div>
      <Button
        onClick={exportToCSV}
        variant="contained"
        style={{
          backgroundColor: "#AF9661",
          color: "white",
          marginBottom: "20px",
        }}
      >
        Export as CSV
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Serial Number</TableCell>
              <TableCell>ID</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Email ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Age</TableCell>
              <TableCell>Gender</TableCell>
              <TableCell>Place</TableCell>
              <TableCell>Team</TableCell>
              <TableCell>Prize ID</TableCell>
              <TableCell>Prize Name</TableCell>
              <TableCell>Created On</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData &&
              filteredData.map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell>{serialNumberStart + index}</TableCell>
                  <TableCell>{row.id}</TableCell>
                  <TableCell>{row.phoneNumber}</TableCell>
                  <TableCell>{row.emailid}</TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.age}</TableCell>
                  <TableCell>{row.gender}</TableCell>
                  <TableCell>{row.place}</TableCell>
                  <TableCell>{row.team}</TableCell>
                  <TableCell>{row.prize_id}</TableCell>
                  <TableCell>{row.prize_name}</TableCell>
                  <TableCell>
                    {new Date(row.created_on).toLocaleString()}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{ marginTop: "20px" }}>
        <TextField
          select
          label="Entries per page"
          value={limit}
          onChange={handleLimitChange}
          variant="outlined"
          style={{ marginRight: "20px" }}
        >
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={20}>20</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={100}>100</MenuItem>
          <MenuItem value={200}>200</MenuItem>
          <MenuItem value={1000}>1000</MenuItem>
          <MenuItem value={10000}>10000</MenuItem>
          <MenuItem value={100000}>100000</MenuItem>
        </TextField>
        <Button
          onClick={handlePreviousPageChange}
          variant="contained"
          disabled={page === 1}
        >
          Previous
        </Button>
        <Button
          onClick={handlePageChange}
          variant="contained"
          disabled={page === totalPages}
        >
          Next
        </Button>
      </div>
    </div>
  );
}

export default GiveawayAdminData;
