import React, { useState, useEffect } from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import { showSuccessPopup, showErrorPopup } from "./Notification";

function Signup({ prizeId, prizeText, hideOverlay, hideAndShowThankYou }) {
  const [step, setStep] = useState(1);
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [place, setPlace] = useState("");
  const [favoriteTeam, setFavoriteTeam] = useState("");

  const [otpGenerated, setOtpGenerated] = useState(false);
  const [resendTimer, setResendTimer] = useState(30);
  const [disableResend, setDisableResend] = useState(false);
  const [otpSent, setOtpSent] = useState(false);

  useEffect(() => {
    let interval;
    if (resendTimer > 0 && disableResend) {
      interval = setInterval(() => {
        setResendTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      clearInterval(interval);
      setDisableResend(false);
      setOtpGenerated(false);
    }
    return () => clearInterval(interval);
  }, [resendTimer, disableResend]);

  const handleGenerateOTP = () => {
    setOtpGenerated(true);
    const apiEndpoint =
      `https://scoreapi.cricfan.tv/api/get_login_sendotp_v2?phone_no=` +
      phoneNumber;
    fetch(apiEndpoint)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.status === 200) {
          setOtpSent(true);
          setDisableResend(true);
          setResendTimer(30);
        } else {
          showErrorPopup(data.message);
        }
      })
      .catch((error) => {
        showErrorPopup(error.message);
      });
  };

  const handleSignup = () => {
    const shouldCallPrizeAPIWhileSignUp = prizeId !== -1;
    const apiEndpoint = `https://scoreapi.cricfan.tv/api/get_rasmus_v2?email=${email}&name=${name}&age=${age}&gender=${gender}&place=${place}&team=${favoriteTeam}&phone_no=${phoneNumber}&prize_id=${prizeId}&prize_name=${prizeText}&otp=${otp}&shouldCallPrizeAPIWhileSignUp=${shouldCallPrizeAPIWhileSignUp}`;

    fetch(apiEndpoint)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.status === 200) {
          localStorage.setItem("jwtToken", data.token);
          if (data.error) {
            showErrorPopup(data.error);
            hideOverlay();
          } else {
            showSuccessPopup("Entry recorded!");
            hideAndShowThankYou();
          }
        } else {
          showErrorPopup(data.message);
        }
      })
      .catch((error) => {
        showErrorPopup(error.message);
      });
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    if (value.length <= 10) {
      setPhoneNumber(value);
    }
  };

  const handleContinue = () => {
    setStep(2);
  };

  return (
    <div>
      <p style={{ color: "#01FF76", fontFamily: "gothic", fontSize: "25px" }}>
        Sign Up Once To Play Forever
      </p>
      <Form>
        {step === 1 && (
          <>
            <Row
              className="mb-3 align-items-center"
              style={{ marginTop: "10px" }}
            >
              <Col style={{ display: "flex", alignItems: "center" }}>
                <Form.Control
                  type="text"
                  placeholder="Enter your name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Col>
            </Row>

            <Row
              className="mb-3 align-items-center"
              style={{ marginTop: "10px" }}
            >
              <Col style={{ display: "flex", alignItems: "center" }}>
                <Form.Control
                  type="number"
                  placeholder="Enter your phone number"
                  value={phoneNumber}
                  style={{ fontSize: "12px", height: "55px" }}
                  onChange={handlePhoneNumberChange}
                />
              </Col>
              <Col xs="auto">
                <Button
                  style={{
                    borderRadius: "5px",
                    width: "100%",
                    backgroundColor: "#01FF76",
                    color: "black",
                    fontFamily: "gothic",
                    fontSize: "15px",
                    border: "0px",
                  }}
                  onClick={handleGenerateOTP}
                  disabled={disableResend}
                >
                  Generate OTP
                </Button>
              </Col>
            </Row>

            {otpGenerated && (
              <div>
                <p style={{ color: "white" }}>
                  {!disableResend && otpGenerated && (
                    <p>You will receive a message with OTP</p>
                  )}
                  {disableResend && ` Re-send OTP in ${resendTimer} seconds`}
                </p>
              </div>
            )}

            {otpSent ? (
              <div>
                <Form.Group controlId="otp">
                  <Form.Control
                    type="text"
                    placeholder="Enter OTP"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    maxLength={4}
                  />
                </Form.Group>
              </div>
            ) : null}

            <Row
              className="mb-3 align-items-center"
              style={{ marginTop: "10px" }}
            >
              <Col style={{ display: "flex", alignItems: "center" }}>
                <Form.Control
                  type="text"
                  placeholder="Enter your email ID"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Col>
            </Row>

            <Row
              className="mb-3 align-items-center"
              style={{ marginTop: "10px", marginRight: "10px" }}
            >
              <Button
                onClick={handleContinue}
                style={{
                  marginLeft: "10px",
                  borderRadius: "5px",
                  backgroundColor: "#01FF76",
                  color: "black",
                  fontFamily: "gothic",
                  fontSize: "20px",
                  border: "0px",
                }}
              >
                Continue
              </Button>
            </Row>
          </>
        )}

        {step === 2 && (
          <>
            <Row
              className="mb-3 align-items-center"
              style={{ marginTop: "10px" }}
            >
              <Col style={{ display: "flex", alignItems: "center" }}>
                <Form.Control
                  type="number"
                  placeholder="Enter your age"
                  value={age}
                  onChange={(e) => setAge(e.target.value)}
                />
              </Col>
            </Row>
            <Row
              className="mb-3 align-items-center"
              style={{ marginTop: "10px" }}
            >
              <Col style={{ display: "flex", alignItems: "center" }}>
                <Form.Control
                  as="select"
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                >
                  <option value="" disabled selected>
                    Select Gender
                  </option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="no">Rather Not Say</option>
                </Form.Control>
              </Col>
            </Row>
            <Row
              className="mb-3 align-items-center"
              style={{ marginTop: "10px" }}
            >
              <Col style={{ display: "flex", alignItems: "center" }}>
                <Form.Control
                  type="text"
                  placeholder="Enter your place"
                  value={place}
                  onChange={(e) => setPlace(e.target.value)}
                />
              </Col>
            </Row>

            <Row
              className="mb-3 align-items-center"
              style={{ marginTop: "10px" }}
            >
              <Col style={{ display: "flex", alignItems: "center" }}>
                <Form.Control
                  as="select"
                  value={favoriteTeam}
                  onChange={(e) => setFavoriteTeam(e.target.value)}
                >
                  <option value="" disabled selected>
                    Your favourite team?
                  </option>
                  <option value="aus">Australia</option>
                  <option value="afg">Afghanistan</option>
                  <option value="bang">Bangladesh</option>
                  <option value="eng">England</option>
                  <option value="india">India</option>
                  <option value="pak">Pakistan</option>
                  <option value="ned">Netherlands</option>
                  <option value="nz">New Zealand</option>
                  <option value="sl">Sri Lanka</option>
                </Form.Control>
              </Col>
            </Row>

            <Row
              className="mb-3 align-items-center"
              style={{ marginTop: "10px", marginRight: "10px" }}
            >
              <Button
                onClick={handleSignup}
                style={{
                  marginLeft: "10px",
                  borderRadius: "5px",
                  backgroundColor: "#01FF76",
                  color: "black",
                  fontFamily: "gothic",
                  fontSize: "20px",
                  border: "0px",
                }}
              >
                Enter Now
              </Button>
            </Row>
          </>
        )}
      </Form>
    </div>
  );
}

export default Signup;
